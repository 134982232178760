import React, { FC, useCallback, useState } from 'react'
import UserAuthDetailsLayout from '../../layouts/Onboarding1/UserAuthDetailsLayout'
import { useNavigate } from 'react-router-dom'
import PageContainer from '../../layouts/core/PageContainer'
import { useBusinessSignup } from '../../context/useBusinessSignup'
import { userAccountService } from '../../services/userAccountService'
import { authenticationService } from '../../services/authenticationService'
import { LOCAL_STORAGE_CURRENT_USER_KEY } from '../../utils/requestOptions'
import { FormErrorMessage } from '../../layouts/inputs/atoms/form-error-message'
import {sendWebViewEvent} from "../../utils/sendWebViewEvent";

interface Props {
  title?: string
}

const UserAuthDetailsScreen: FC<Props> = ({ title = 'Home' }) => {
  const [isSaving, setIsSaving] = useState(false)
  const { updateEmailAndPassword, businessSignupData } = useBusinessSignup()
  const [createAccountError, setCreateAccountError] = useState()

  let navigate = useNavigate()

  const createUserAccount = useCallback(
    async (email: string, password: string) => {
      try {
        const user = await userAccountService.createAccount(
          email,
          password,
          false
        )
        const loginUser = await authenticationService.login(email, password)

        localStorage.setItem(
          LOCAL_STORAGE_CURRENT_USER_KEY,
          JSON.stringify(loginUser)
        )

        // If this is hosted in a webview, send a message to the webview container
        // so that the user can be logged in automatically.
        sendWebViewEvent({
          eventType: 'CredentialsEntered',
          userEmailAddress: email,
          userPassword: password,
        });

        return true
      } catch (error: any) {
        setCreateAccountError(
          error?.errorCode === 'email_address_already_registered'
            ? error?.message
            : 'An error has occurred trying to create your account, please press continue again !'
        )
        console.log('createUserAccount error', error)
      }

      return false
    },
    []
  )

  const onSubmit = useCallback(
    async (
      email: string,
      password: string,
      agreeToGeneralTerms: boolean,
      agreeToIndiesTerms: boolean | undefined
    ) => {
      // Start by clearing any previous auth key,
      // as this isn't relevant for the new user.
      localStorage.removeItem(LOCAL_STORAGE_CURRENT_USER_KEY)

      updateEmailAndPassword?.({
        email,
        password,
        agreeToGeneralTerms,
        agreeToIndiesTerms: true,
      })
      setIsSaving(true)
      const hasCreatedAccount = await createUserAccount(email, password)
      setIsSaving(false)
      console.log('hasCreatedAccount', hasCreatedAccount)
      if (hasCreatedAccount) {
        navigate('/onboarding/2')
      }
    },
    [createUserAccount, navigate, updateEmailAndPassword]
  )

  return (
    <PageContainer
      stepTitle="1 / 6"
      width={'w-1/6'}
      title={
        <>
          <span className="block">Signup for xplore is fast.</span>
          <span className="block">No commitments or long term contracts.</span>
          <span className="block">Let's get started.</span>
        </>
      }>
      <div className="">
        <div className="mb-12 mt-4 bg-slate-100 sm:mt-12 sm:p-8">
          <UserAuthDetailsLayout
            isSaving={isSaving}
            onSubmit={onSubmit}
            businessType={businessSignupData?.businessType}
          />
          {createAccountError && (
            <FormErrorMessage className="flex justify-center">
              {createAccountError}
            </FormErrorMessage>
          )}
        </div>
      </div>
    </PageContainer>
  )
}

export default UserAuthDetailsScreen
