import React, { FC, useRef, useState } from 'react'
import { TrashIcon } from '@heroicons/react/solid'
import {object, string} from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import FormValidationError from '../core/FormValidationError'
import BusySubmitButton from "../buttons/BusySubmitButton";

interface Props {
  isSaving: boolean,
  onSubmit: (file: File) => void,
}

interface IFormInputs {
  file: string | undefined
}

const schema = object({
  file: string().required('* You need to upload proof of identity'),
})

const VerifyIdentityLayout: FC<Props> = ({
  onSubmit,
  isSaving,
                                         }) => {
  const {
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
  })

  const [imageProof, setImageProof] = useState<File | null>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleImageUploadChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length >= 1) {
      setImageProof(e.target.files[0])
      setValue('file', e.target.files[0].name)
    }
  }

  const handleImageDelete = () => {
    setImageProof(null)
    setValue('file', undefined)
  }

  const onSubmitPressed = () => {
    if (imageProof) {
      onSubmit(imageProof)
    }
  }

  return (
    <div className="">
      <form onSubmit={handleSubmit(onSubmitPressed)}>
        <div className="space-y-8">
          <div className="space-y-2">
            <h2>IF THIS IS A LTD COMPANY:</h2>
            <div>We require a scan or photo of:</div>
            <ul>
              <li className="ml-10 list-disc">
                At least one directors' driving licence or passport, as proof of
                identity.
              </li>
            </ul>
          </div>

          <div className="space-y-2">
            <h2>IF THIS IS A SOLE TRADER OR PARTNERSHIP:</h2>
            <div>We require a scan or photo of:</div>
            <ul className="">
              <li className="ml-10 list-disc">
                The sole trader’s/partners’ driving licence or passport. It must
                show the full name, photograph and date of birth. Note:
                Provisional licences are not accepted.
              </li>
              <li className="ml-10 mt-4 list-disc sm:mt-0">
                OR a government-issued document without a photo, plus a utility
                bill/council tax letter (the latter must feature the sole
                trader’s/partners’ home address)
              </li>
            </ul>
          </div>
          <div className="mt-6 w-full rounded-md bg-slate-100 p-4 hover:bg-slate-200/70">
            <button
              type="button"
              onClick={() => fileInputRef?.current?.click()}
              className="w-full">
              <div className="flex flex-col items-start">
                <div className="btn_small">Attach Proof of Identity</div>
                <div className="mt-2">
                  JPEG, GIF and PNG formats are accepted.
                </div>
              </div>
            </button>
            <input
              accept="image/png, image/jpeg, image/gif"
              size={10}
              onChange={handleImageUploadChange}
              multiple={false}
              ref={fileInputRef}
              type="file"
              hidden
            />
          </div>
          <FormValidationError errors={errors} name="file"/>
          {imageProof && (
            <div className="flex items-center space-x-4">
              <div>{imageProof.name}</div>
              <button onClick={handleImageDelete}>
                <TrashIcon className="w-6 text-gray-300 opacity-60 hover:opacity-100"/>
              </button>
            </div>
          )}
        </div>
        <div className="mt-12 flex flex-row justify-center">
          <BusySubmitButton isBusy={isSaving} title="Continue"/>
        </div>
      </form>
    </div>
  )
}

export type {Props as VerifyIdentityLayoutProps}
export default VerifyIdentityLayout
