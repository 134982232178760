import {WebViewContainerEvent} from "../types/WebViewContainerEvent";

/**
 * Sends a message to the React Native WebView that contains this web page, if it exists.
 * @param messageDetails the message to send.
 */
export function sendWebViewEvent(messageDetails: WebViewContainerEvent) {
  // @ts-ignore
  window.ReactNativeWebView?.postMessage(JSON.stringify(messageDetails));
}
